var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          'id',
          'name',
          'short_name',
          { key: 'identification', render: function (value) { return _vm.parsers.numeric(value, 'nit'); } }, // TODO: Use id type ?
          'email',
          Object.assign({}, _vm.getHelperObject('property_type'), {link: { id: 'property_type_id', href: '#/admin/types/property/XXX/edit' }}),
          Object.assign({}, _vm.getHelperObject('manager'), {link: { id: 'manager_id', href: '#/admin/managers/XXX/edit' }}),
          Object.assign({}, _vm.helpers.validIcons, {key: 'is_printable'}),
          _vm.helpers.activeBadge
        ],"config":{
          url: 'admin/properties',
          route: '/admin/properties',
          name: 'property',
          lname: 'properties' /* TODO: dynamic plural ? */,
          filters: [{ key: 'manager', config: { select_all: true } }, { key: 'is_printable', use_object: { 0: 'No', 1: 'Si' } }, 'active'] // TODO: Make it global ?
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }