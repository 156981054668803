<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            'id',
            'name',
            'short_name',
            { key: 'identification', render: value => parsers.numeric(value, 'nit') }, // TODO: Use id type ?
            'email',
            { ...getHelperObject('property_type'), link: { id: 'property_type_id', href: '#/admin/types/property/XXX/edit' } },
            { ...getHelperObject('manager'), link: { id: 'manager_id', href: '#/admin/managers/XXX/edit' } },
            { ...helpers.validIcons, key: 'is_printable' },
            helpers.activeBadge
          ]"
          :config="{
            url: 'admin/properties',
            route: '/admin/properties',
            name: 'property',
            lname: 'properties' /* TODO: dynamic plural ? */,
            filters: [{ key: 'manager', config: { select_all: true } }, { key: 'is_printable', use_object: { 0: 'No', 1: 'Si' } }, 'active'] // TODO: Make it global ?
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Properties',
  mixins: [crudMixin]
}
</script>
